.a,
.c {
  fill: rgba(255, 255, 255, 0) !important;
}
.b {
  fill: #f59220 !important;
}
.icon-font {
  font-family: icons_font;
}
#right-navbar {
  height: 100% !important;
  background-color: #f8f8f8;
  width: 240px;
  right: 0;
  top: 0;
  z-index: 1000;
  position: fixed;
  overflow-y: auto;
  transition: transform 0.3s;
}
#menu-row:active #right-navbar {
  transform: translateX(0);
}
.header {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px #0000000d;
}
#menu-x {
  float: right;
  cursor: pointer;

  fill: #1d1d1d;
  transition: fill 0.3s;
}
#menu-x:hover {
  fill: #c0222e;
}
#navbar-category {
  max-height: 70%;
  overflow-y: auto;
}
#arrow {
  position: absolute;
  left: -100px;
  top: 0;
  bottom: 0;
  margin: auto;
  fill: #dc8b00;
}
#logo {
  height: 90%;
  margin-bottom: auto;
  margin-top: auto;
  width: auto;
}
#menu-row {
  position: absolute;
  right: -100px;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  width: 48px;
  height: 33.349;
}

.text-d {
  color: #000;
}
.logo_navbar {
  width: 56px;
  height: 56px;
}
@media screen and (min-width: 1000px) and (max-width: 1400px) {
  .resteurantDisc {
    visibility: hidden !important;
  }
  #menu-row {
    position: absolute;
    right: 0px;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    width: 48px;
    height: 33.349;
  }
  #arrow {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .resteurantDisc {
    visibility: hidden !important;
  }
  #menu-row {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    width: 48px;
    height: 33.349;
  }
  #arrow {
    display: none;
  }
}
@media screen and (min-width: 570px) and (max-width: 768px) {
  #header_text_1,
  #header_text_2 {
    font-size: 1rem;
  }
  .resteurantDisc {
    visibility: hidden !important;
  }
  #menu-row {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    width: 48px;
    height: 33.349;
  }
  #arrow {
    display: none;
  }
}
@media screen and (max-width: 570px) {
  #header_text_1,
  #header_text_2 {
    font-size: 0.85rem;
    white-space: nowrap;
  }
  .resteurantDisc {
    visibility: hidden !important;
  }
  #menu-row {
    position: absolute;
    right: 22px;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    width: 30px;
    height: auto;
  }
  #arrow {
    display: none;
  }
  #logo {
    height: 80%;
    margin-bottom: auto;
    margin-top: auto;
    width: auto;
  }
}
