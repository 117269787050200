* {
  box-sizing: border-box !important;
}
button:active,
button:focus,
a:focus,
button:focus {
  -ms-outline: none !important;
  -o-outline: none !important;
  -moz-outline: none !important;
  outline: none !important; /* CSS 3 */
}
button:active,
button:focus {
  -moz-outline: none !important;
  outline: none !important; /* CSS 3 */
}
* {
  box-sizing: border-box !important;
}
body {
  overflow-y: scroll !important;
}

.upper {
  text-transform: uppercase;
}
.lower {
  text-transform: lowercase;
}
.row {
  width: 100% !important;
}
@font-face {
  font-family: Roboto-Black;

  src: url(fonts/Roboto-Black.ttf);
}
@font-face {
  font-family: Roboto-Light;

  src: url(fonts/Roboto-Light.ttf);
}
@font-face {
  font-family: Roboto-Bold;

  src: url(fonts/Roboto-Bold.ttf);
}
.Bold {
  font-family: Roboto-Black;
}
.bold {
  font-weight: bold;
}
.Lite {
  font-family: Roboto-Light;
}
.fs1 {
  font-size: 1rem;
}
.fs2 {
  font-size: 0.95rem;
}
.fs3 {
  font-size: 0.9rem;
}
.b-card {
  overflow: auto;
}
.invisible-text {
  font-size: 0px;
  color: rgba(255, 255, 255, 0);
}
.min-h {
  min-height: 160px !important;
}
.pointer {
  cursor: pointer;
}
.clicked {
  border-radius: 5px;
}
.popup {
  width: 100%;
  height: auto;
  background: white;
  top: 0px;
  z-index: 1000;
  left: 120%;
  right: 100%;
  margin: 0;
  border: 5px solid red;
  border-radius: 5px;
}
.popup2 {
  width: 50%;
  height: auto;
  background: white;
  top: 0px;
  z-index: 1000;
  left: 25%;

  margin: 0;
  border: 5px solid red;
  border-radius: 5px;
}
@media screen and (min-width: 990px) and (max-width: 1200px) {
}
@media screen and (min-width: 768px) and (max-width: 990px) {
  .popup {
    width: 100%;
    height: auto;
    background: white;
    top: 0px;
    z-index: 1000;
    left: 2%;
    right: 0%;
    margin: 0;
    border: 5px solid red;
    border-radius: 5px;
  }
  .popup2 {
    width: 50%;
    height: auto;
    background: white;
    top: 0px;
    z-index: 1000;
    left: 25%;
    right: 0%;
    margin: 0;
    border: 5px solid red;
    border-radius: 5px;
  }
}
@media screen and (min-width: 570px) and (max-width: 768px) {
  .popup {
    width: 50%;
    height: auto;
    background: white;
    top: 0px;
    z-index: 1000;
    left: 2.7%;
    right: 2.7%;
    margin: 0;
    border: 5px solid red;
    border-radius: 5px;
  }
  .popup2 {
    width: 50%;
    height: auto;
    background: white;
    top: 0px;
    z-index: 1000;
    left: 0%;
    right: 0%;
    margin: 0;
    border: 5px solid red;
    border-radius: 5px;
  }
}
@media screen and (min-width: 10px) and (max-width: 570px) {
  .popup {
    width: 100%;
    height: auto;
    background: white;
    top: 0px;
    z-index: 1000;
    left: 3%;
    right: 0%;

    margin: 0;
    border: 5px solid red;
    border-radius: 5px;
  }
  .popup2 {
    width: 50%;
    height: auto;
    background: white;
    top: 0px;
    z-index: 1000;
    left: 0%;
    right: 0%;
    margin: 0;
    border: 5px solid red;
    border-radius: 5px;
  }
}
