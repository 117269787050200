.mine-background {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: block;
  height: 100% !important;
  overflow: hidden;
}
.cont {
  width: auto !important;
  height: auto !important;
  padding-left: 30px !important;
  display: table;
  float: left;
}
.res {
  display: inline-block;
  width: 24px !important;
  height: 24px !important;

  border-radius: 5px;
  border: 2px solid #1d1d1d;
  position: absolute;
  left: 0;
  top: 50;
  bottom: 50;
  margin: 0;
}
.res2 {
  padding-top: 2px;
  font-size: 10px;
  display: table-cell;
  vertical-align: middle;
}
.res22 {
  padding-top: 2px;
  font-size: 15px;
  display: table-cell;
  vertical-align: middle;
}
@media screen and (min-width: 1000px) and (max-width: 1200px) {
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
}
@media screen and (min-width: 570px) and (max-width: 768px) {
}
@media screen and (max-width: 570px) {
  .res2 {
    padding-top: 2px;
    font-size: 9px;
    display: table-cell;
    vertical-align: middle;
  }
  .res22 {
    padding-top: 2px;
    font-size: 13px;
    display: table-cell;
    vertical-align: middle;
  }
}
